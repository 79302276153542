import { BulletList as BulletListEditor } from '@tiptap/extension-bullet-list';
import Link from '@tiptap/extension-link';
import OrderedList from '@tiptap/extension-ordered-list';
import Placeholder from '@tiptap/extension-placeholder';
import { Underline as UnderlineEditor } from '@tiptap/extension-underline';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import clsx from 'clsx';
import { isFunction, isString } from 'lodash-es';
import { AnimatePresence, motion } from 'motion/react';
import React from 'react';
import {
  Button as AriaButton,
  ButtonProps,
  DialogTrigger,
  TooltipTrigger,
} from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { TextField } from '../../molecules/text-field/text-field';
import { BottomSheet } from '../bottom-sheet/bottom-sheet';
import { Button } from '../button/button';
import { Dialog } from '../dialog/dialog';
import { FieldError, FieldLabel, FieldProps } from '../field/field';
import Icon from '../icon/icon';
import { Modal } from '../modal/modal';
import { Pressable } from '../pressable/pressable';
import { Text } from '../text/text';
import { Tooltip } from '../tooltip/tooltip';

interface RichTextProps extends FieldProps {
  name: string;
  defaultValue?: string;
  placeholder?: string;
  errorMessage?: string | string[];
  className?: string;
  labelClassName?: string;
  showToolbar?: boolean;
  onChange?: (val: string) => void;
}

export function RichText({
  name,
  defaultValue,
  label,
  placeholder = 'Type your message here...',
  errorMessage,
  className,
  labelClassName,
  showToolbar = true,
  onChange,
}: RichTextProps) {
  const editor = useEditor({
    onUpdate: ({ editor }) => {
      onChange && onChange(editor.getText());
    },
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      UnderlineEditor,
      Placeholder.configure({
        placeholder,
        emptyNodeClass:
          'first:after:text-grey-600 first:after:font-normal first:after:content-[attr(data-placeholder)] first:after:pointer-events-none first:after:absolute first:after:top-0 first:after:w-full',
      }),
      OrderedList.configure({
        HTMLAttributes: {
          keepAttributes: true,
          style: 'list-style-type: decimal',
        },
      }),
      BulletListEditor.configure({
        HTMLAttributes: {
          keepAttributes: true,
          style: 'list-style-type: disc',
        },
      }),
    ],
    content: defaultValue,
  });

  if (!editor) {
    return null;
  }

  return (
    <div>
      <FieldLabel className={twMerge('m-0 mb-2 flex text-base font-bold', labelClassName)}>
        {label}
      </FieldLabel>
      {showToolbar && (
        <AnimatePresence initial={false}>
          <MenuBar editor={editor} />
        </AnimatePresence>
      )}
      <div
        className={twMerge(
          'z-1 focus-within:ring-grey-700 ring-grey-500 relative flex h-[200px] max-h-[200px] w-full min-w-0 flex-col overflow-hidden overflow-y-auto rounded-lg bg-white ring-1 transition',
          errorMessage && 'ring-negative-400 focus-within:ring-negative-400 ring-1',
          className,
        )}>
        <EditorContent
          className="text-grey-900 border-md child:outline-none child:w-full [&_a]:text-ocean-400 [&_a]:hover:text-ocean-400 [&_a]:focus-within::underline flex h-full max-w-none flex-1 p-4 font-medium [&_a]:cursor-pointer [&_a]:no-underline [&_a]:hover:underline [&_ol]:list-decimal [&_ol]:pl-6 [&_ul]:list-disc [&_ul]:pl-6"
          data-testid="editor-content"
          editor={editor}
        />
        <input
          dir="auto"
          name={name}
          type="hidden"
          value={editor.isEmpty ? '' : editor.getHTML()}
        />
      </div>
      {errorMessage && (
        <FieldError label={isString(label) ? label : 'rich-text'}>{errorMessage}</FieldError>
      )}
    </div>
  );
}

function MenuBar({ editor }: { editor: Editor }) {
  return (
    <motion.div
      animate={{ opacity: 1, height: 'auto' }}
      className="ring-grey-500 bg-grey-100 -mb-2 flex items-center gap-2 overflow-x-auto rounded-t-lg px-4 py-2 pb-4 ring-1"
      exit={{ opacity: 0, height: 0 }}
      initial={{ opacity: 0, height: 0 }}>
      <Bold editor={editor} />
      <Italic editor={editor} />
      <LinkMenuBarButton editor={editor} />
      <Underline editor={editor} />
      <BulletList editor={editor} />
      <NumberedList editor={editor} />
    </motion.div>
  );
}

function Bold({ editor }: { editor: Editor }) {
  return (
    <MenuBarButton
      isActive={editor.isActive('bold')}
      isDisabled={!editor.can().chain().focus().toggleBold().run()}
      label="Bold"
      onPress={() => editor.chain().focus().toggleBold().run()}>
      <Icon className="h-5 w-5" name="bold" />
    </MenuBarButton>
  );
}
function Italic({ editor }: { editor: Editor }) {
  return (
    <MenuBarButton
      isActive={editor.isActive('italic')}
      isDisabled={!editor.can().chain().focus().toggleItalic().run()}
      label="Italic"
      onPress={() => editor.chain().focus().toggleItalic().run()}>
      <Icon className="h-5 w-5" name="italic" />
    </MenuBarButton>
  );
}
function Underline({ editor }: { editor: Editor }) {
  return (
    <MenuBarButton
      isActive={editor.isActive('underline')}
      isDisabled={!editor.can().chain().focus().toggleUnderline().run()}
      label="Underline"
      onPress={() => editor.chain().focus().toggleUnderline().run()}>
      <Icon className="h-5 w-5" name="underline" />
    </MenuBarButton>
  );
}
function BulletList({ editor }: { editor: Editor }) {
  return (
    <MenuBarButton
      isActive={editor.isActive('bulletList')}
      label="Bullet List"
      onPress={() => editor.chain().focus().toggleBulletList().run()}>
      <Icon className="h-5 w-5" name="bullet-list" />
    </MenuBarButton>
  );
}
function NumberedList({ editor }: { editor: Editor }) {
  return (
    <MenuBarButton
      isActive={editor.isActive('orderedList')}
      label="Numbered List"
      onPress={() => editor.chain().focus().toggleOrderedList().run()}>
      <Icon className="h-5 w-5" name="number-list" />
    </MenuBarButton>
  );
}

interface MenuBarButtonProps extends ButtonProps {
  isActive?: boolean;
  label: string;
}

function LinkMenuBarButton({ editor }: { editor: Editor }) {
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  function handleSetLink(e: React.FormEvent<HTMLFormElement>, close: () => void) {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.currentTarget);
    const url = formData.get('url');
    const previousUrl = editor.getAttributes('link').href;

    if (!url) {
      if (previousUrl) {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
      } else {
        setErrorMessage('This field is required');
        return;
      }
    } else {
      const correctURL = formatURL(url as string);

      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .insertContent(
          `<a href="${correctURL}" target="_blank">${formData.get('link-name') || url}</a> `,
        )
        .run();

      setErrorMessage('');
    }
    close();
  }
  const getLinkInfo = (link: Record<string, string>) => {
    let linkText = '';

    editor?.state.doc.descendants((node) => {
      if (node.marks.some((mark) => mark.type.name === 'link' && mark.attrs.href === link.href)) {
        linkText = node.textContent;
        return false; // Stop traversal
      }
    });

    return linkText;
  };

  const formatURL = (url: string): string => {
    // checks if the URL starts with http:// or https://
    if (/^(https?:\/\/)/i.test(url)) {
      return url;
    }
    return `http://${url}`;
  };

  const handleClose = (onClose: () => void) => {
    setErrorMessage('');
    onClose();
  };
  return (
    <DialogTrigger>
      <MenuBarButton isActive={editor.isActive('link')} label="Link">
        <Icon className="h-5 w-5" name="link" />
      </MenuBarButton>
      {window.innerWidth > 768 ? (
        <Modal isDismissable={false}>
          <Dialog>
            {({ close }) => (
              <>
                <Dialog.Header showCloseButton={false} title="Add link">
                  <Pressable
                    aria-label="Close Add link"
                    className="hover:text-grey-900 hover:bg-grey-200 flex h-8 w-8 items-center justify-center rounded-full text-black transition-colors md:pt-0"
                    onPress={() => handleClose(close)}>
                    <Icon className="hidden h-4 w-4 md:block" name="close" />
                    <Icon className="h-6 w-6 md:hidden" name="chevron-right" />
                  </Pressable>
                </Dialog.Header>
                <form
                  className="flex h-full w-full flex-col items-start gap-x-2 p-3"
                  onSubmit={(e) => handleSetLink(e, close)}>
                  <Dialog.Body className="w-full flex-1">
                    <TextField
                      defaultValue={editor.getAttributes('link').href}
                      errorMessage={errorMessage}
                      key={`url-${editor.getAttributes('link').href}`}
                      label="*Link"
                      name="url"
                      placeholder="Insert URL"
                    />
                    <TextField
                      defaultValue={getLinkInfo(editor.getAttributes('link'))}
                      key={`url-${getLinkInfo(editor.getAttributes('link'))}`}
                      label="Text"
                      name="link-name"
                      placeholder="Name your link"
                    />
                  </Dialog.Body>
                  <Dialog.Footer className="self-end">
                    <>
                      <Button className="mr-1" onPress={() => handleClose(close)} variant="text">
                        Close
                      </Button>
                      <Button rounded="lg" type="submit" variant="secondary">
                        Link
                      </Button>
                    </>
                  </Dialog.Footer>
                </form>
              </>
            )}
          </Dialog>
        </Modal>
      ) : (
        <BottomSheet isDismissable={false}>
          {({ close }) => (
            <>
              <BottomSheet.Header subTitle="Add link" />
              <form onSubmit={(e) => handleSetLink(e, close)}>
                <BottomSheet.Body>
                  <div className="p-4 pb-14">
                    <TextField
                      className="pb-6"
                      defaultValue={editor.getAttributes('link').href}
                      errorMessage={errorMessage}
                      key={`url-${editor.getAttributes('link').href}`}
                      label="*Link"
                      name="url"
                      placeholder="Insert URL"
                    />
                    <TextField
                      defaultValue={getLinkInfo(editor.getAttributes('link'))}
                      key={`url-${getLinkInfo(editor.getAttributes('link'))}`}
                      label="Text"
                      name="link-name"
                      placeholder="Name your link"
                    />
                  </div>
                </BottomSheet.Body>
                <BottomSheet.Footer>
                  <Button className="mr-1" onPress={() => handleClose(close)} variant="text">
                    Close
                  </Button>
                  <Button rounded="lg" type="submit" variant="secondary">
                    Link
                  </Button>
                </BottomSheet.Footer>
              </form>
            </>
          )}
        </BottomSheet>
      )}
    </DialogTrigger>
  );
}

function MenuBarButton({ className, children, isActive, label, ...props }: MenuBarButtonProps) {
  return (
    <TooltipTrigger closeDelay={0} delay={500}>
      <AriaButton
        className={(bag) =>
          twMerge(
            clsx(
              'disabled:text-grey-400 rac-hover:bg-grey-200 rounded p-1.5 transition focus:outline-none disabled:cursor-not-allowed',
              isActive ? 'bg-grey-300 rac-hover:bg-grey-400' : '',
            ),
            isFunction(className) ? className(bag) : className,
          )
        }
        {...props}
        aria-label={label}>
        {children}
      </AriaButton>
      <Tooltip>
        <Text slot="menu-bar-button-label" variant="p5" weight="medium">
          {label}
        </Text>
      </Tooltip>
    </TooltipTrigger>
  );
}
